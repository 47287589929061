(function() {
    'use strict';

    window.aax = window.aax || {};
    window.aax.execute = window.aax.execute || [];

    let usPrivacy = {enabled: false};
    let euPrivacy = {enabled: false};
    let isUserInEurope = null;
    let isUserInUsa = null;
    const euCountryCodes = ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SE', 'SI', 'SK'];


    function doLoop(iter, fn) {
        // doLoop loops over an array or NodeList and applies the given function to each item. The function will receive two arguments: 1: the item, 2: the item index
        iter = isObject(iter) ? Object.keys(iter) : iter;
        iter.forEach(fn);
    }

    function getCookie(cname) {
        if (!navigator.cookieEnabled) {
            log('Cookies are disabled in your browser', {force: 1, level: 'warn'});
            return false;
        }
        // TEST: getCookie returns a cookie value given a cookieName
        const name = cname + '=',
            decodedCookie = decodeURIComponent(document.cookie),
            cookieArray = decodedCookie.split(';');
        let cookieMatch;
        doLoop(cookieArray, function(thisCookie) {
            while (thisCookie.charAt(0) === ' ') {
                thisCookie = thisCookie.substring(1);
            }
            if (thisCookie.indexOf(name) === 0) {
                cookieMatch = thisCookie.substring(name.length, thisCookie.length);
            }
        });
        return cookieMatch;
    }

    function coerceType(str) {
        if (typeof str === 'string') {
            // TEST: if a numeric string is passed to coerceType, it will return a number
            if (str.match(/^[0-9]+$/)) {
                return convertStringToNumber(str);
            // TEST: if a "true" or "false" string is passed to coerceType, it will return a boolean
            } else {
                return convertStringToBoolean(str);
            }
        }
        return str;
    }

    function convertQueryStringToObject(queryString, lowerCaseKeys) {
        queryString = decodeURIComponent(queryString);
        // if we don't pass the second param (lowerCaseKeys), we'll assume that we want the keys lowercased
        lowerCaseKeys = (lowerCaseKeys !== undefined) ? lowerCaseKeys : true;
        const obj = {};
        queryString.replace(/[?&]?([^=&]+)=([^&]*)/gi, function(m, key, value) {
            if (lowerCaseKeys) {
                obj[key.toLowerCase()] = value;
            } else {
                obj[key] = value;
            }
        });
        return obj;
    }

    function convertStringToBoolean(str) {
        // TEST: if we pass 'true' or 'false' as a string to convertStringToBoolean, the returned result is a boolean
        // TEST: if we pass a string other than 'true' or 'false' convertStringToBoolean, the returned result remains a string
        if ((typeof str === 'string' && (str === 'true' || str === 'false' || str === '')) || str === undefined || str === null) {
            return (str === 'true') || false;
        }
        return str;
    }

    function convertStringToNumber(str) {
        // TEST: if we pass a string to karmads.utilities.convertStringToNumber, the returned result is either a number or NaN
        return parseInt(str, 10);
    }

    function isObject(x) {
        // TEST: if we pass an array to karmads.utilities.isObject, it will return false
        // TEST: if we pass a non-array object to karmads.utilities.isObject, it will return true
        return (x !== null && typeof x === 'object' && !Array.isArray(x) && !NodeList.prototype.isPrototypeOf(x));
    }

    function log(msg) {
        const c = window.console;
        if (c) {
            c.log(msg);
        }
    }

    function isEuCountryCode(countryCode) {
        if (!countryCode) {
            return null;
        }
        return euCountryCodes.indexOf(countryCode) !== -1;
    }

    function getConsentCookie() {
        // TEST: the user privacy consent cookie is called OptanonConsent
        let oneTrustCookie = getCookie('OptanonConsent');
        // if the OptanonConsent cookie is present
        if (oneTrustCookie) {
            // convert the cookie to an object, without lowercasing the keys
            oneTrustCookie = convertQueryStringToObject(getCookie('OptanonConsent'), false);
            // log the cookie data to the KARMA log
            log('OneTrust cookie is present, with this data: ' + JSON.stringify(oneTrustCookie));
            return oneTrustCookie;
        }
        // if the OptanonConsent cookie is not present, return null
        return null;
    }

    function hasUserConsented(consentData, otGeoLoc) {
        // TEST: if the user privacy consent cookie is present, and the 'groups' parameter of that cookie contains '4:0', the user has opted out of advertising-related cookies
        consentData = consentData || getConsentCookie();
        if (consentData && consentData.hasOwnProperty('groups')) {
            return !(consentData.groups.indexOf('4:0') !== -1);
        }
        otGeoLoc = otGeoLoc || getOneTrustGeoLocation();
        return !userInEurope(otGeoLoc);
    }

    function getOneTrustGeoLocation() {
        if (window.otStubData && typeof window.otStubData.userLocation === 'object') {
            return window.otStubData.userLocation;
        }
        return null;
    }

    function userInUsa(otGeoLoc) {
        otGeoLoc = otGeoLoc || getOneTrustGeoLocation();
        if (otGeoLoc && otGeoLoc.hasOwnProperty('country')) {
            isUserInUsa = (otGeoLoc.country === 'US');
        }
        return isUserInUsa;
    }

    function userInEurope(otGeoLoc) {
        otGeoLoc = otGeoLoc || getOneTrustGeoLocation();
        if (otGeoLoc && otGeoLoc.hasOwnProperty('country')) {
            isUserInEurope = isEuCountryCode(otGeoLoc.country);
        }
        return isUserInEurope;
    }

    function checkPrivacy() {
        // TEST: karmads.privacy has a method called check
        const consentData = getConsentCookie(),
            otGeoLoc = getOneTrustGeoLocation(),
            isThisUserInEurope = userInEurope(otGeoLoc),
            otAlertBoxClosed = getCookie('OptanonAlertBoxClosed') || null,
            otGpcEnabled = consentData ? !!(consentData.hasOwnProperty('isGpcEnabled') && coerceType(consentData.isGpcEnabled) === 1) : null;

        if (isThisUserInEurope) {
            euPrivacy.enabled = true;
        }

        if (consentData) {
            const hasUserOptedOut = !hasUserConsented(consentData);
            // TEST: if the OptanonConsent cookie is present, and the user has NOT opted out of advertising-related cookies, usPrivacy.enabled and euPrivacy.enabled are both false
            // TEST: if the OptanonConsent cookie is present, the user is in the US, they've opted out of advertising-related cookies, and the OptanonAlertBoxClosed is not set, usPrivacy.enabled is true
            // TEST: if the OptanonConsent cookie is present, the user is in the US, they've opted out of advertising-related cookies, and the OptanonAlertBoxClosed cookie is not set, usPrivacy.enabled is false
            usPrivacy.enabled = !!(userInUsa(otGeoLoc) && hasUserOptedOut && (otGpcEnabled || otAlertBoxClosed !== null));
            // TEST: if the OptanonConsent cookie is present, the user is in Europe, and they've opted out of advertising-related cookies, euPrivacy.enabled is true
            euPrivacy.enabled = !!(isThisUserInEurope && hasUserOptedOut);
        }

        if (isThisUserInEurope) {
            window.aax.execute.push(function(){
                window.aax.setGDPRApplicable(true);
                log('aax.setGDPRApplicable: true');
                window.aax.consentExists(!euPrivacy.enabled);
                log('aax.consentExists: ' + !euPrivacy.enabled);
                window.aax.setNonPersonalizedAds(euPrivacy.enabled);
                log('aax.setNonPersonalizedAds: ' + euPrivacy.enabled);
            });
        }

        if (isUserInUsa) {
            window.aax.execute.push(function(){
                window.aax.limitDataProcessing(usPrivacy.enabled);
                log('aax.limitDataProcessing: ' + usPrivacy.enabled);
            });           
        }
    }

    window.aax = window.aax || {};
    var aax = window.aax;
    aax.initTime = new Date().getTime();
    aax.pubId = "AAXV53D7Q";
    aax.ver = "1.2";
    aax.hst = window.location.hostname;
    var u = "https://c.aaxads.com/aax.js?pub=" + aax.pubId + "&hst=" + aax.hst + "&ver=" + aax.ver;
    function load(u) {
        var s = document.createElement("script"), p = document.getElementsByTagName("script")[0];
        s.async = true; s.src = u;p.parentNode.insertBefore(s, p);
    }

    if (document.readyState === 'complete') {
        checkPrivacy();
        load(u);
    } else {
        window.addEventListener('load', function() {
            checkPrivacy();
            load(u);
        });
    }

}());